.autocomplete-card {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px;
}

.location-results {
  margin-top: 10px;
}

.location-card {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
}

.location-card.expanded {
  background-color: #e9ecef;
}

.expanded-content {
  padding-top: 10px;
}

.location-card p {
  margin: 5px 0;
}
